<template>
  <div class="flex h-screen bg-gray-50 dark:bg-gray-900" :class="{ 'overflow-hidden': isSideMenuOpen }">
    <!-- 左侧 菜单区-->
    <aside class="z-20 hidden w-56 overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0">
      <div class="py-4 text-gray-500 dark:text-gray-400">
        <a class="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200" href="#">综合通知平台
        </a>
        <!-- 菜单区 -->
        <ul>
          <li class="relative px-6 mt-4">
            <button
              class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              @click="closeEmailMenu" aria-haspopup="true">
              <span class="inline-flex items-center"><svg class="w-5 h-5" aria-hidden="true" fill="none"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z">
                  </path>
                </svg><span class="ml-4">邮件通知</span></span><svg class="w-4 h-4" aria-hidden="true" fill="currentColor"
                viewBox="0 0 20 20">
                <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
              </svg>
            </button>
            <div v-if="isEmailMenuOpen">
              <ul x-transition:enter="transition-all ease-in-out duration-300"
                x-transition:enter-start="opacity-25 max-h-0" x-transition:enter-end="opacity-100 max-h-xl"
                x-transition:leave="transition-all ease-in-out duration-300"
                x-transition:leave-start="opacity-100 max-h-xl" x-transition:leave-end="opacity-0 max-h-0"
                class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
                aria-label="submenu">
                <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                  <router-link to="add">
                    <span class="w-full">新增通知</span>
                  </router-link>
                </li>
                <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                  <router-link to="pre">
                    <span class="w-full">待发通知</span>
                  </router-link>
                </li>
                <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                  <router-link to="complete">
                    <span class="w-full">已发通知</span>
                  </router-link>
                </li>
                <!-- <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                  <router-link to="fee">
                    <span class="w-full">消费月报</span>
                  </router-link>
                </li> -->
                <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                  <router-link to="apidoc">
                    <span class="w-full">API说明</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="relative px-6 mt-2">
            <button
              class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              @click="openFeeReport" aria-haspopup="true">
              <span class="inline-flex items-center">
                <svg class="w-5 h-5" aria-hidden="true" fill="none" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z">
                  </path>
                </svg>
                <span class="ml-4">消费月报</span>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </aside>
    <!-- 右侧显示区-->
    <div class="flex flex-col flex-1">
      <!-- 右侧-- 顶部控制区-->
      <header class="z-10 py-4 bg-white shadow-md dark:bg-gray-800">
        <div class="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
          <!-- 中间区域 内容 -->
          <div class="flex justify-center flex-1 lg:mr-32">
            <div class="relative w-full max-w-xl mr-6 focus-within:text-purple-500"></div>
          </div>
          <!-- 右侧显示内容, 信用点数量 -->
          <ul class="flex items-center flex-shrink-0 space-x-6">
            <!-- Theme toggler -->
            <li class="flex">
              <!-- 信用点:<span>1400</span> -->
              欢迎
            </li>
            <!-- Notifications menu -->
            <li class="relative">
              <!-- 用户编号:<span>1400</span> -->
              访问
            </li>
            <!-- Profile menu -->
            <li class="relative">

            </li>
          </ul>
        </div>
      </header>
      <!-- 右侧-- 内容显示区  -->
      <main class="h-full pb-16 overflow-y-auto">
        <div class="container px-6 mx-auto grid">
          <!-- 这个只能是展位效果，不能包含任何内容-->
          <router-view></router-view>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  data() {
    return {
      count: 0,
      isEmailMenuOpen: true,
      isSideMenuOpen: true,
    };
  },
  methods: {
    closeProfileMenu() { },
    closeNotificationsMenu() { },
    closeSideMenu() { },
    closeNotificationsMenu2() { },
    closeEmailMenu() {
      this.isEmailMenuOpen=!this.isEmailMenuOpen
     },
    openFeeReport() {
      this.$router.push('/home/fee')
    }
  },
};
</script>
