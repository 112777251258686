import axios from 'axios'

const Service = axios.create({
    timeout: 8000,
    // 定义前缀请求
    baseURL: "",
    headers: {
        "Content-type": "application/json;charset=utf-8"
    }
})

// 请求拦截,请求前的处理
Service.interceptors.request.use(param => {
    console.log('inter request ')
    // 增加jwt的支持,方便后台及进行验证只用
    const v_token = localStorage.getItem('jwt_token')
    const v_login_flag = localStorage.getItem('is_login')
    if (v_login_flag && v_login_flag){
        param.headers['authorization']=v_token
    }
    return param
},error => {
    if (error) {
        console.log('request error')
    } else {
        console.log('inter request  error ')
    }
    // return Promise.reject(error)
})

// 响应拦截，相应后的处理
Service.interceptors.response.use(config => {
    console.log('inter response ')
    // 增加jwt的支持，方便后台验证只用
    const v_token = config.headers['authorization']
    if(v_token){
        localStorage.setItem('jwt_token',v_token)
    }
    return config.data
}, error => {
    if (error) {
        console.log('error')
    } else {
        console.log('inter error ')
    }
    // return Promise.reject(error)
})

// post 封装
export const nipPost = config => {
    return Service({
        ...config,
        method: "post",
        data: config.data
    })
}

// get 封装
export const nipGet = config => {
    return Service({
        ...config,
        method: "get",
        data: config.data
    })
}
