<template>
  <div class="container mx-auto px-8">
    <div class="relative inset-x-0 top-7 h-32 bg-purple-400">
      <img class="absolute left-10 top-4 h-24 w-24" src="../assets/1.png">
      <p class="leading-loose text-5xl">综合通知平台</p>
    </div>
    <div class="gap-8 columns-3">
      <div class="h-1"> </div>
      <div class="">
        <div class="logo_img">
          <img class="mx-auto object-cover h-72 w-72" src="../assets/nip-logo.png">
        </div>
      </div>
      <div class="h-1"> </div>
    </div>
    <div class="gap-8 columns-3">
      <div class="h-1">
      </div>
      <div class="">
        <div class="logo_foot">
          <div class="leading-loose text-2xl">登陆码：<span>{{ logincode }}</span></div>
        </div>
      </div>
      <div class="h-1"> </div>
    </div>
    <div class="gap-8 columns-3">
      <div class="h-1">
      </div>
      <div class="">
        <div class="logo_foot">
          <div>请用微信扫码登陆</div>
        </div>
      </div>
      <div class="h-1"> </div>
    </div>
    <div class="gap-8 columns-3">
      <div class="h-1"> </div>
      <div class="">
        <div class="logo_foot">
          <div>VX客服：mx20222052</div>
        </div>
      </div>
      <div class="h-1"> </div>
    </div>
    <div class="gap-8 columns-3">
      <div class="h-1"> </div>
      <div class="">
        <div class="logo_foot">
          <div>本网址由<a href="https://www.027dream.com" target="_blank" class="underline  underline-offset-1 px-2">www.027dream.com</a> 提供技术服务</div>
        </div>
      </div>
      <div class="h-1"> </div>
    </div>
  </div>
</template>
<script>
import { postloginApi } from '@/request'
// @ is an alias to /src
export default {
  // 数据对象
  data() {
    return {
      count: 1,
      logincode: '正在获取...',
      isGetCode: false,
      timerid: null
    }
  },
  // `mounted` 是生命周期钩子，之后我们会讲到
  mounted() {
    // `this` 指向当前组件实例
    console.log(this.count) // => 1
    // 数据属性也可以被更改
    this.count = 2
    this.timerid = setInterval(() => {
      this.login()
    }, 15000);
    this.login()
  },
  methods: {
    login() {
      // 登陆事件
      console.log('exec login ')
      if (this.isGetCode) {
        postloginApi({ 'logincode': this.logincode }).then(response => {
          console.log('exec 1 login =', response)
          if (response) {
            // 处理登陆事件
            if (response.code == 2000) {
              if (this.timerid) {
                clearTimeout(this.timerid)
              }
              // 处理jwt
              localStorage.setItem('jwt_token',response.token)
              localStorage.setItem('is_login',true)
              this.$router.push('/home')
            }
            if (response.code == 2001) {
              this.logincode = response.msg
            }
          }
        })
      } else {
        postloginApi({ 'logincode': 'null' }).then(response => {
          console.log('exec 2 login =', response)
          if (response) {
            // 处理登陆事件
            this.isGetCode = true
            this.logincode = response.msg
            clearTimeout(this.timerid)
            this.timerid = setInterval(() => {
              this.login()
            }, 5000);
          }
        })
      }
    }
  }
}
</script>
<style lang="css">
.logo_img {
  padding-top: 80px
}

.logo_foot {
  padding-top: 20px
}
</style>
