import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/add',
    component: HomeView,
    children: [
      // {path:'/',name:'home',component:HomeView},
      { path: 'add', name: 'add', component: ()=>import('../views/email/AddView.vue') },
      { path: 'pre', name: 'pre', component: ()=>import('../views/email/PreNotifyView.vue') },
      { path: 'complete', name: 'complete', component: ()=>import('../views/email/CompleteView.vue') },
      { path: 'apidoc', name: 'apidoc', component: ()=>import('../views/email/ApiDocView.vue') },
      { path: 'fee', name: 'fee', component: ()=>import('../views/FeeView.vue') }
    ]
  }
  //  ,
  //  { 
  //    path: '/about',
  //    name: 'about',
  //    // route level code-splitting
  //    // this generates a separate chunk (about.[hash].js) for this route
  //    // which is lazy-loaded when the route is visited.
  //    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
