import { nipPost,nipGet } from "./service";

export const  getFeeApi = data =>{
    return nipGet({
        url:"json/fee123.json",
        data:data
    })
}

export const  postFeeApi = data =>{
    return nipPost({
        url:"json/fee123.json",
        data:data
    })
}

export const  postloginApi = data =>{
    return nipPost({
        url:"/login.do",
        data:data
    })
}

export const  postlogoutApi = data =>{
    return nipPost({
        url:"/logout.do",
        data:data
    })
}

export const  postPageApi = data =>{
    let v_data = nipPost({
        url:"/page.do",
        data:data
    })
    console.log('request.js postPageApi')
    return v_data
}